<script setup lang="ts">
import type { Plan, Product } from '~/models/order'

interface Props {
  plans: Plan[]
  selector?: boolean
  current?: Product
}

const props = defineProps<Props>()
const emits = defineEmits<{
  (e: 'productSelected', product: Product): void
}>()

const selectedProductId = ref<number | undefined>()

const standard = computed(() => {
  return props.plans.find(p => p.code === 'standard')
})

const premium = computed(() => {
  return props.plans.find(p => p.code === 'premium')
})

const products = computed(() => {
  return props.plans.flatMap(p => p.products)
})

const selectedPlan = computed(() => {
  if (standard.value?.products.find(p => p.id === selectedProductId.value))
    return 'standard'

  if (premium.value?.products.find(p => p.id === selectedProductId.value))
    return 'premium'
})

const selectedProduct = computed(() => {
  return products.value?.find(p => p.id === selectedProductId.value)
})

function isCurrent(product: Product) {
  return props.current?.id === product.id
}

function isYearly(product: Product) {
  return product.durationCount === 12 && product.durationUnit === 'month'
}

const displayPrices = computed(() => {
  const prices = {
    standard: standard.value?.products[0]?.price,
    premium: premium.value?.products[0]?.price
  }

  if (!prices.standard || !prices.premium) {
    return { standard: undefined, premium: undefined }
  }

  if (
    selectedProduct.value &&
    selectedPlan.value &&
    isYearly(selectedProduct.value)
  ) {
    // const newPrice = prices[selectedPlan.value]?.multiply(10 / 12.0)
    // if (newPrice) {
    //   prices[selectedPlan.value] = newPrice
    // }
  }

  return prices
})
</script>

<template>
  <div class="plans">
    <div v-if="standard" class="plan">
      <hr />
      <span class="name">{{ $t('plans.standard') }}</span>
      <span class="price">
        <sup>{{ displayPrices.standard?.symbol() }}</sup>{{ displayPrices.standard?.value() }}
      </span>
      <span>/ {{ $t('common.month') }}</span>

      <div class="features">
        <ul>
          <li v-for="f of standard.description.features">{{ f }}</li>
        </ul>
      </div>

      <div class="actions">
        <div v-if="props.selector" class="plan-options">
          <label
            v-for="product of standard.products"
            :class="{ selected: product.id === selectedProductId }"
          >
            <div class="left">
              <input
                type="radio"
                name="product_id"
                class="styled"
                :class="`product_id_${product.id}`"
                :value="product.id"
                v-model="selectedProductId"
                @click="emits('productSelected', product)"
                :disabled="isCurrent(product)"
              >
              {{ product.description }}
            </div>
            <div class="tags">
              <span v-if="isCurrent(product)" class="tag">
                Current
              </span>
              <template v-else-if="isYearly(product)">
                <span class="tag">{{ $t('plans.best_value') }}</span>
                <span class="success tag">{{ $t('plans.two_months_free') }}</span>
              </template>
            </div>
          </label>
        </div>
        <slot name="standard" :products="standard.products" />
      </div>
    </div>

    <div v-if="premium" class="plan">
      <hr />
      <span class="name">{{ $t('plans.premium') }}</span>
      <span class="price">
        <sup>{{ displayPrices.premium?.symbol() }}</sup>{{ displayPrices.premium?.value() }}
      </span>
      <span>/ {{ $t('common.month') }}</span>
      <div class="features">
        <ul>
          <li v-for="f of premium.description.features">{{ f }}</li>
        </ul>
      </div>
      <div class="actions">
        <div v-if="props.selector" class="plan-options">
          <label
            v-for="product of premium.products"
            :class="{ selected: product.id === selectedProductId }"
          >
            <div class="left">
              <input
                type="radio"
                name="product_id"
                class="styled"
                :value="product.id"
                v-model="selectedProductId"
                @click="emits('productSelected', product)"
                :disabled="isCurrent(product)"
              >
              {{ product.description }}
            </div>
            <div class="tags">
              <span v-if="isCurrent(product)" class="tag">
                {{ $t('plans.current') }}
              </span>
              <template
                v-else-if="isYearly(product)">
                <span class="tag">{{ $t('plans.best_value') }}</span>
                <span class="success tag">{{ $t('plans.two_months_free') }}</span>
              </template>

            </div>
          </label>
        </div>
        <slot name="premium" :products="premium.products" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.plans {
  @media (min-width: $breakpoint-md) {
    display: flex;
    justify-content: space-between;
    text-align: left;
  
    .plan { flex: 1 1 50%; }
    .plan:first-child {
      margin-right: 1.5rem;
    }

    .plan:last-child {
      margin-left: 1.5rem;
    }
  }
}

.plan + .plan {
  margin-top: 1.5rem;

  @media (min-width: $breakpoint-md) {
    margin-top: 0;
  }
}

.plan {
  .name {
    display: block;
    font-size: 1.5rem;
    font-weight: $font-bold-weight;
  }

  .price {
    font-size: 2rem;
  }
  
  .features {
    height: 7rem;
    li + li { margin-top: 0.3rem; }
  }
  .actions {
    margin-top: 2rem;
  }
}

.plan-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: $border-radius-rounded;
    border: 1px solid $color-light-gray;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-black, 0.05);
    }

    &.selected {
      border-color: transparent;
      background-color: rgba($color-success, 0.1);
      box-shadow: $color-success 0 0 0 3px inset;
    }
    
    .left {
      display: flex;
      align-items: center;
    }

    input {
      margin-right: 0.75rem;
    }
  }

  label + label {
    margin-top: 1rem;
  }

  .tag {
    font-size: 0.75rem;
  }

  .tag + .tag {
    margin-left: 0.25rem;
  }
}
</style>
